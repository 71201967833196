import React from 'react'
// import { Helmet } from 'react-helmet'
import PageWrapper from '../../components/PageWrapper'
import Hero from '../../sections/common/Hero'
import GetStarted from '../../sections/landing1/GetStarted'
import { Section, Title, Text, Box, Button } from '../../components/Core'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { theme } from '../../utils'
import heroImg from '../../assets/image/svg/coinsOffered.svg'
import portfolioMgmt from '../../assets/image/svg/management-portfolio.svg'
import cryptoSync from '../../assets/image/svg/portfolioMgmt.svg'
import MetaData from '../../components/MetaData' 
import Link from '../../components/Link'
import ExchFeesTable from '../../components/Table/ExchFeesTable'
import HeaderConfig from '../../components/Header/HeaderConfig'
import styled from 'styled-components'

const ImageShadow = styled(Box)`
  img {
    border-radius: 0px;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 52px 42px ${theme.colors.shadow}`});
  }
`
const ListItem = styled.li`
  font-size: 21px;
  font-weight: 300;
  padding-top: 0px;
  padding-bottom: 12px;
  margin-left: 20px !important;
  padding-left: 8px !important;
`

const Lp4 = () => {
  const { t, ready } = useTranslation( ['marketingLps', 'helloCrypto'], { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const sectionBackgroundDark = userDarkMode
    ? theme.colors.black
    : theme.colors.lightGray


  const customFeeSection = {
    leftCol: '3',
    rightCol: '8',
    emailInput: false,
    background: theme.colors.bgDarkGray,
    title: t('helloCrypto.sameCoins'),
    firstP: t('helloCrypto.whyPay'),
  }

  const i18nLang = HeaderConfig().i18nLang()
  const metaData = HeaderConfig().metaData
  const langs = metaData.site.siteMetadata.languages
  let lang = HeaderConfig().setLang(langs, i18nLang)


  const textColor = userDarkMode ? theme.colors.light : theme.colors.dark


  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero
          bg={sectionBackgroundDark}
          title={t('precision')}
          firstP={t('fast')}
          externalLink={true}
          externalText={t('signup')}
          externalUrl={process.env.LS_SIGNUP}
          img={true}
          imgSrc={heroImg}
        />
        
        {/* Trade often for less: Section */}
        <Section bg={userDarkMode ? theme.colors.dark : theme.colors.light} >
          <Container>
            <Row className="pb-4">
              <Col lg="6">
              <Title variant="highlight" color={textColor}>
                    {t('tradeWithConfidence')}
                </Title>
                <Text color={textColor} mb={24}>
                    {t('superior')}
                </Text>
                <ul>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('precisionBullet1')}
                    </ListItem>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('precisionBullet2')}
                    </ListItem>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('precisionBullet3')}
                    </ListItem>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('precisionBullet4')}
                    </ListItem>
                </ul>
                <a href={process.env.LS_SIGNUP}>
                  <Button>{t('signup')}</Button>
                </a>
              </Col>
              <Col lg="6">
              <ImageShadow
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <img
                    width="100%"
                    height="auto"
                    alt=""
                    className="img-fluid"
                    src={portfolioMgmt}
                  />
                </ImageShadow>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* Trade with transparency : Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row className="pb-4">
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                    {t('less')}
                </Title>
                <Text color={textColor} mb={24}>
                    {t('priceYouPay')}
                </Text>
              </Col>
              <Col lg="8">
                <ExchFeesTable />
              </Col>
            </Row>
          </Container>
        </Section>

        {/* Advanced platform : Section */}
        <Section bg={userDarkMode ? theme.colors.dark : theme.colors.light}>
          <Container>
            <Row>
              <Col lg="7">
                <Title variant="highlight" color={textColor}>
                  {t('cryptoSync')}
                </Title>
                <Text color={textColor} className="mb-4">
                {t('only')}
                </Text>
                <Link to={`/${lang}/portfolio-management/`}>
                  <Button>{t('helloCrypto.cryptoSYNCbtn')}</Button>
                </Link>
              </Col>
              <Col lg="5">
                <ImageShadow
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <img
                    width="100%"
                    height="auto"
                    alt=""
                    className="img-fluid"
                    src={cryptoSync}
                  />
                </ImageShadow>
              </Col>
            </Row>
          </Container>
        </Section>
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default Lp4